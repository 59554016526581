'use client';

import dynamic from 'next/dynamic';

export const HeaderMenuLinksTrackDynamic = dynamic(
  () => import('./HeaderMenuLinksTrack').then((mod) => mod.HeaderMenuLinksTrack),
  {
    ssr: false,
  },
);
