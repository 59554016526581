'use client';

import { clsx } from 'clsx';
import { usePathname } from 'next/navigation';
import { forwardRef, MouseEventHandler, ReactNode, useCallback, useState } from 'react';
import { Link } from 'src/general/components/Link/Link';

interface Props {
  href: string;
  children: ReactNode;
  onClick?: MouseEventHandler;
  onMouseEnter?: () => void;
  submenuActive?: boolean;
  className?: string;
  'data-testid'?: string;
}

export const HeaderMenuLink = forwardRef<HTMLAnchorElement, Props>(
  ({ children, className, submenuActive, onMouseEnter, href, ...props }, ref) => {
    const [hover, setHover] = useState<boolean>(false);
    const [focus, setFocus] = useState<boolean>(false);
    const pathname = usePathname();
    // Special case for homepage links
    const active = pathname ? pathname.startsWith(href) : false;

    const onMouseEnterCallback = useCallback(() => {
      setHover(true);
      if (onMouseEnter) {
        onMouseEnter();
      }
    }, [ref, onMouseEnter]);

    return (
      <Link
        {...props}
        href={href}
        className={clsx(className, 'link', { active, hover, focus, submenuActive })}
        onMouseEnter={onMouseEnterCallback}
        onMouseLeave={() => setHover(false)}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        ref={ref}
      >
        {children}
      </Link>
    );
  },
);

HeaderMenuLink.displayName = 'HeaderMenuLink';
