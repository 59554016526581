'use client';

import { ComponentProps, FC, useRef } from 'react';
import { headerMenuStore } from 'src/stores/headerMenuStore';
import { shallow } from 'zustand/shallow';
import { HeaderMenuLink } from './HeaderMenuLink';

type Props = {
  submenuIndex: number;
} & ComponentProps<typeof HeaderMenuLink>;

export const HeaderMenuLinkWithSubmenu: FC<Props> = ({ submenuIndex, ...props }) => {
  const ref = useRef<HTMLAnchorElement | null>(null);
  const { refFn, currentSubmenuIndex, setIsKeyboardOpen, setCurrentSubmenuIndex } = headerMenuStore(
    (state) => ({
      currentSubmenuIndex: state.currentSubmenuIndex,
      refFn: state.refFn,
      setIsKeyboardOpen: state.setIsKeyboardOpen,
      setCurrentSubmenuIndex: state.setCurrentSubmenuIndex,
    }),
    shallow,
  );

  return (
    <HeaderMenuLink
      {...props}
      ref={ref}
      onClick={(event) => {
        event.preventDefault();
        setIsKeyboardOpen(true, submenuIndex);
        if (refFn) {
          refFn(ref.current);
        }
      }}
      onMouseEnter={() => {
        setCurrentSubmenuIndex(submenuIndex);
        if (refFn) {
          refFn(ref.current);
        }
      }}
      submenuActive={submenuIndex === currentSubmenuIndex}
    />
  );
};
