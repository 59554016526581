'use client';

import dynamic from 'next/dynamic';

export const HeaderMenuFloatingDynamic = dynamic(
  () => import('./HeaderMenuFloating').then((mod) => mod.HeaderMenuFloating),
  {
    ssr: false,
  },
);
