'use client';

import { produce } from 'immer';
import { FC, ReactNode } from 'react';

import { storeFactory } from './storeFactory';

interface HeaderMenuStoreStateData {
  open: boolean;
  isKeyboardOpen: boolean;
  currentSubmenuIndex: number | null;
  refFn: ((ref: HTMLElement | null) => void) | null;
}

interface HeaderMenuStoreStateFunctions {
  setOpen: (open: boolean) => void;
  setIsKeyboardOpen: (isKeyboardOpen: boolean, currentSubmenuIndex?: number) => void;
  setCurrentSubmenuIndex: (currentSubmenuIndex: number | null) => void;
  setRefFn: (refFn: ((ref: HTMLElement | null) => void) | null) => void;
}

const {
  store: headerMenuStore,
  StoreProvider: HeaderMenuStoreProvider,
  useCreateStore: useCreateHeaderMenuStore,
} = storeFactory<HeaderMenuStoreStateData, HeaderMenuStoreStateFunctions>(
  () => ({
    open: false,
    isKeyboardOpen: false,
    currentSubmenuIndex: null,
    refFn: null,
  }),
  (set) => ({
    setOpen: (open: boolean) => {
      set((state) =>
        produce(state, (draft) => {
          draft.open = open;
        }),
      );
    },
    setIsKeyboardOpen: (isKeyboardOpen: boolean, currentSubmenuIndex?: number) => {
      set((state) =>
        produce(state, (draft) => {
          if (isKeyboardOpen) {
            draft.open = true;
          }
          if (typeof currentSubmenuIndex === 'number') {
            draft.currentSubmenuIndex = currentSubmenuIndex;
          }
          draft.isKeyboardOpen = isKeyboardOpen;
        }),
      );
    },
    setCurrentSubmenuIndex: (currentSubmenuIndex: number | null) => {
      set((state) =>
        produce(state, (draft) => {
          if (currentSubmenuIndex !== null) {
            draft.open = true;
          }
          draft.currentSubmenuIndex = currentSubmenuIndex;
        }),
      );
    },
    setRefFn: (refFn: ((ref: HTMLElement | null) => void) | null) => {
      set((state) =>
        produce(state, (draft) => {
          draft.refFn = refFn;
        }),
      );
    },
  }),
);

export { headerMenuStore };

export const HeaderMenuStore: FC<{ children: ReactNode }> = ({ children }) => {
  const createHeaderMenuStore = useCreateHeaderMenuStore({});

  return <HeaderMenuStoreProvider createStore={createHeaderMenuStore}>{children}</HeaderMenuStoreProvider>;
};
